import { render, staticRenderFns } from "./mechanical-record2.vue?vue&type=template&id=61d7bb3a&scoped=true&"
import script from "./mechanical-record2.vue?vue&type=script&lang=js&"
export * from "./mechanical-record2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61d7bb3a",
  null
  
)

export default component.exports